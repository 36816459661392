@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;700&display=swap");
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
}

p {
  line-height: 1.55;
  color: #333;
  font-family: "Roboto", sans-serif;
}

h1 {
  font-size: 62px;
}

h2 {
  font-size: 30px;
  margin-bottom: 65px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
a {
  transition: 0.5s;
  color: #199eb8;
}

a:hover {
  color: #23c2e1;
}

/*scroll bar*/
.scroll-bar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #e9e9e9;
}

.scroll-bar::-webkit-scrollbar {
  width: 12px;
  background-color: #e9e9e9;
}

.scroll-bar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #828282;
}

.text-gray {
  color: #bbbbbb;
}
h1,
/* ==================================================
 Elements
 ================================================== */
.btn {
  background-color: #a6f2ff;
  border: none;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-weight: 800;
  color: #196296;
  font-size: 14px;
  padding: 15px 45px;
  border-radius: 25px !important;
}
button.link {
  padding: 5px 0 !important;
  border-radius: inherit !important;
  margin-top: 5px !important;
  font-size: 12px !important;
  border: none;
  background: transparent;
  text-decoration: underline;
  font-size: 16px !important;
  color: #196296;
}
button.link:hover {
  color: #1484d4;
}

.btn-primary {
  padding: 10px 25px;
}

.btn-primary.focus,
.btn-primary:focus,
.btn:hover,
.btn:active .btn:focus,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: #36d4ef !important;
  color: #196296 !important;
}

.btn:focus {
  color: #196296;
}

.btn-ghost {
  border: 3px solid #fff;
  background-color: transparent;
}

.btn-ghost:hover {
  background-color: #fff;
  color: #199eb8;
}

.btn-danger,
.btn-danger:hover,
.btn-danger:focus {
  color: #fff !important;
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
  padding: 7px 17px;
}

.btn-sm {
  padding: 5px 10px;
  font-size: 12px;
}

section {
  padding: 85px 0;
}

.btn-wrapper {
  padding: 0;
}

.card {
  position: relative;
  padding: 0;
  border: 0;
  border-radius: 0;
  margin: 0;
  overflow: hidden;
  /* background-color: #f4fdff; */
}

a:hover {
  text-decoration: none !important;
}

.scrolltop {
  display: none;
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background: #199eb8;
  color: #fff;
  text-align: center;
  font-size: 24px;
  z-index: 1;
}

.scrolltop:hover,
.scrolltop:active,
.scrolltop:focus {
  color: #fff !important;
  opacity: 0.75;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  background: #199eb8;
  height: 70px;
}

#header #logo {
  margin: 14px 25px 0 0;
}

#header #logo h1 {
  font-size: 36px;
  margin: 0;
  padding: 2px 0;
  line-height: 1;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  letter-spacing: 3px;
  text-transform: uppercase;
}

#header #logo h1 a,
#header #logo h1 a:hover {
  color: #fff;
}

#header #logo img {
  padding: 0;
  margin: 0;
  max-height: 40px;
}

@media (max-width: 768px) {
  #header {
    height: 50px;
  }
  #header #logo {
    margin: 10px 0 0 0;
  }
  #header #logo h1 {
    padding: 2px 0;
    font-size: 26px;
  }
  #header #logo img {
    max-height: 30px;
  }
}

#header .social-nav {
  margin-top: 15px;
}

#header .social-nav a {
  font-size: 24px;
  margin-left: 15px;
  color: #fff;
}

#header .social-nav a:hover {
  color: rgba(255, 255, 255, 0.75);
}
.user {
  /*    width: 43px;
   height: 43px;
   display: flex;
   justify-content: center;
   align-items: center;
   background: #eaeaea;
    padding: 5px; 
   border: none;
   border-radius: 50%;
   background-color: #cff8ff;
   box-shadow: 0px 0px 28px -17px rgb(0 196 255);*/
  cursor: pointer;
  background: #eaeaea;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 67px;
  height: 65px;
  padding: 0;
  /* overflow: hidden; */
  background: transparent;
  border: 2px solid #fbfbfb;
  margin: 0 auto;
}
.user .icon {
  //  box-shadow: 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
  width: 67px;
  height: 65px;
  border-radius: 50%;
  object-fit: cover;
}
.user-header-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  /*gap: 7px;*/
  padding: 7px;
}
.user-header-info h3 {
  line-height: inherit;
  background: transparent;
}
@media only screen and (max-width: 1366px) {
  .header-logo {
    width: 76%;
  }
  .user,
  .user .icon {
    width: 47px;
    height: 45px;
  }
}
@media only screen and (max-width: 500px) {
  .user {
    width: 46px;
    height: 44px;
  }
  .user .icon {
    width: 46px;
    height: 44px;
    font-size: 10px;
  }
  .header-logo {
    width: 60%;
  }
  .user-header-info {
    align-items: flex-start;
    padding: 0 7px;
  }
}
/* .header-logo{
width: 11%;
} */
/*Custom CSS Start*/
.lang-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
}

.lang-bar2 {
  display: flex;
  align-items: center;
}

.lang-bar2 .circle:hover {
  cursor: pointer;
}

.header-home .lang-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /*position: absolute;
 top: 0;
 right: 0;*/
}
.header-home .hero-brand a {
  visibility: hidden;
}

.start-row {
  // margin-top: 65px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // position: absolute;
  width: 100%;
  z-index: 0;
}

.lang-bar span {
  color: #199eb8;
}
.main-container {
  display: flex;
}
@media only screen and (max-width: 800px) {
  .main-container {
    flex-direction: column;
  }
}
@media only screen and (max-width: 500px) {
  .main-container {
    display: flex;
    flex-direction: column;
  }
  .hero-brand img {
    width: 100px;
  }
}
.main {
  padding: 0;
}
.main-sidebar {
  padding: 3em;
}
.main-sidebar .signin {
  display: flex;
  width: 50%;
  padding: 12px 0;
  justify-content: space-between;
  gap: 14px;
}

.main-sidebar .signin input[type="text"] {
  width: inherit;
  border: 1px solid #62c0fd;
  border-radius: 25px;
  text-align: center;
}
.main-sidebar .signin input[type="text"]:focus {
  outline: none;
}
@media only screen and (max-width: 1025px) {
  .main-sidebar {
    padding: 2em;
  }
}
@media only screen and (max-width: 500px) {
  .main-sidebar {
    padding: 1em;
  }
}
.inner-box {
  display: flex;
  flex-direction: column;
}
.inner-box .icons {
  height: 50px;
  opacity: 0.6;
}
.inner-box-1,
.inner-box-2,
.inner-box-3 {
  display: flex;
  /*  justify-content: space-between;*/
  background-color: #ffffff;
  min-height: 86px;
}
.inner-box .top {
  justify-content: space-between;
  display: flex;
  padding: 10px;
}
.inner-box .bottom {
  padding: 10px;
  display: flex;
  flex-direction: column;
  font-weight: 300;
  line-height: 1.2;
}
@media only screen and (max-width: 1366px) {
  .inner-box {
    font-size: 10px;
  }
}

@media only screen and (max-width: 800px) {
  .inner-box {
    font-size: 13px;
    display: inline-block;
  }
}

@media only screen and (max-width: 500px) {
  .inner-box {
    font-size: 13px;
    display: inline-block;
  }
}

.inner-box-1 {
  border-radius: 7px;
  color: #333;
}
.inner-box-2 {
  border-radius: 7px;
  color: #333;
}
.inner-box-3 {
  border-radius: 7px;
  color: #333;
}
.inner-box-1 .number {
  color: #ff5a6c;
}
.inner-box-2 .number {
  color: #07d895;
}
.inner-box-3 .number {
  color: #ff8c56;
}
.number {
  font-size: 32px;
  font-weight: 700;
}
.bold {
  font-weight: 700;
}
.inner-main {
  padding: 1em;
  gap: 1em;
}
#Registration {
  padding-top: 32px;
  transition: 1s;
}
.verficationSection {
  display: flex;
  justify-content: center;
}
.verficationSection h3 {
  font-size: 18px;
  font-weight: 600;
}
.bg {
  background-image: url("../../images/stethoscope.png");
  background-position: right bottom;
  background-repeat: no-repeat;
}
.inner-right {
  padding: 0 51px;
}
@media only screen and (max-width: 768px) {
  .inner-right {
    padding: inherit;
  }
}
@media only screen and (max-width: 500px) {
  @media only screen and (max-width: 768px) {
    .inner-right {
      padding: 16px;
    }
  }
}
/*.hid-split:nth-letter(even){
       color:red;
   }*/
.card {
  min-height: 190px;
  background-color: #fbfbfb;
  border: none !important;
  /* -webkit-box-shadow: 0px 0px 21px -5px rgba(0,0,0,0.33);
-moz-box-shadow: 0px 0px 21px -5px rgba(0,0,0,0.33);
box-shadow: 0px 0px 21px -5px rgba(0,0,0,0.33); */
  transition: min-height 0.25s ease-in;
  background-color: #f3f9ff;
  border-radius: 14px !important;
  overflow: visible;
}
.card-img {
  width: 24%;
  display: block;
  position: absolute;
  right: 0;
  top: -50px;
}
.tabcontent .card {
  background-color: transparent;
}
.card .id-card {
  background: #f7f7f7 !important;
}
.id-card {
  border: 1px solid #d6d6d6 !important;
  padding: 11px 32px;
  //  box-shadow: 0px 0px 28px -17px rgb(0 0 0 / 75%);
  width: 370px;
  background: #f7f7f7 !important;
}
.id-card .top {
  border-bottom: 1px solid #efefef;
  display: flex;
  justify-content: center;
}
.id-card .mid {
  display: flex;
  flex-direction: column;
  padding: 18px 0;
  font-size: 13px;
}
.id-card .mid .user-image {
  display: flex;
  justify-content: center;
  align-items: center;
}
.id-card .mid .info {
  padding: 8px;
}
.id-card .mid .qr-code {
  text-align: center;
  border-top: 2px solid #d6d6d6;
  padding-top: 12px;
}
.id-card .mid .qr-code .qr-img {
  width: 25%;
  margin: 0 auto;
}
.share-btn {
  width: 40%;
  display: flex;
  justify-content: end;
}
.id-card .mid .inner-row {
  margin-bottom: 4px;
  display: flex;
  justify-content: space-between;
}
.id-card .mid .sub-title {
  font-weight: 500;
  width: 44%;
}
.id-card .mid .user-image {
  width: 13em;
  height: 13em;
  padding: 0;
  overflow: hidden;
  background: transparent;
  border: 2px solid #fbfbfb;
  margin: 0 auto;
}
.id-card .mid .user-image .photo {
  //  box-shadow: 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

// medical history items

#past-medical-history {
  position: relative;
}
.history-list-item {
  width: 65px;
  display: flex;
  flex-direction: column;
  border-right: 1px dashed #a6a6a6;
}
.history-list-item-wrap .day-wrap {
  display: flex;
  padding: 10px 0px;
  color: #777;
  position: relative;
}
.history-list-item-wrap .day-wrap-selected {
  border-radius: 20px 0px 0px 20px;
  border: 1px dashed #22c0e8;
  border-right: none;
  color: #22c0e8 !important;
}
.history-list-item-wrap .day-border-wrap {
  align-self: stretch;
  width: 65px;
  border-right: 1px dashed #a6a6a6;
}
.history-list-item-wrap .day-wrap .month {
  transform-origin: center;
  transform: rotate(-90deg);
  text-transform: uppercase;
  margin-left: 10px;
  font-size: 14px;
}
.history-list-item-wrap .day-wrap .day {
  font-size: 24px;
  font-weight: bold;
}

.history-list-item-wrap .day-wrap .dot {
  position: absolute;
  right: -7px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  border: 1px solid #c6c6c6;
  border-radius: 50%;
  width: 12px;
  height: 12px;
}

.history-list-item .year-wrap {
  display: flex;
  align-items: center;
  width: 65px;
}

.history-list-item .year-wrap .year {
  color: #22c0e8;
  margin-right: 5px;
  font-weight: bold;
}

.history-list-item .year-wrap .year-bar {
  width: 100%;
  height: 3px;
  background-color: #22c0e8;
}

.history-list-item-wrap .encounter-wrap {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
}
.history-list-item-wrap .encounter-wrap .encounter-list-wrap {
  margin-top: 16px;
  margin-left: 40px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}
.encounter-wrap .encounter-list-wrap .encounter-name {
  margin-bottom: 10px;
  font-size: 14px;
  cursor: pointer;
}
.encounter-wrap .encounter-list-wrap .encounter-name:hover {
  text-decoration: underline;
}
.detailed-view-wrap {
  width: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 72px;
  background-color: #ffffff;
  padding-left: 15px;
  padding-top: 42px;
}
#prescriptions .detailed-view-wrap {
  padding-right: 15px;
}
#past-medical-history .detailed-view-wrap h4 {
  font-weight: bold;
  color: #22c0e8;
}
.detailed-view-wrap #closeBtn {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  color: #22c0e8;
}

.detailed-view-wrap #vital-signs .sign {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  padding: 10px;
  justify-content: space-between;
  border: 1px solid #eee;
  margin: 5px 0px;
}
.detailed-view-wrap #vital-signs .sign .sign-name {
  color: #22c0e8;
}

.detailed-view-wrap #diagnosisList .diagnosis {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  justify-content: space-between;
  border: 1px solid #eee;
  margin: 5px 0px;
}
.detailed-view-wrap #diagnosisList .diagnosis .diagnosis-name {
  color: green;
}
.detailed-view-wrap #diagnosisList .diagnosis .diagnosis-code {
  color: #22c0e8;
}

.encounter-details {
  margin-bottom: 40px;
}

.detailed-view-wrap #prescriptionList .p-type {
  font-weight: bold;
  display: inline-block;
  margin-top: 20px;
}

.detailed-view-wrap #prescriptionList .prescription {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 100%;
  margin: 10px 0px;
  border: 1px solid #eee;
  padding: 10px;
}

.detailed-view-wrap #prescriptionList .p-product {
  color: #22c0e8;
  font-size: 15px;
}

.detailed-view-wrap #clinical-notes .note-item h5 {
  font-size: 18px;
}
.detailed-view-wrap #clinical-notes .note-item .question-item {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border: 1px solid #eee;
  margin: 10px 0px;
  padding: 10px;
}

.detailed-view-wrap #clinical-notes .note-item .question-item .question {
  color: #22c0e8;
}

.timeline .year {
  color: #22c0e8;
  font-size: 20px;
  font-weight: bold;
}

.timeline .day-list .vital-item-wrapper {
  padding: 20px 0px 20px 20px;
  border-left: 1px solid #aaa;
  margin-left: 20px;
  position: relative;
}
.timeline .day-list .vital-item {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 10px 10px;
  border: 1px solid #eee;
}

.timeline .day-list .vital-item-wrapper .dot {
  position: absolute;
  height: 12px;
  width: 12px;
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 50%;
  top: 50%;
  left: -6px;
  transform: translateY(-50%);
}

.vital-item .vital-value {
  color: #22c0e8;
  font-weight: bold;
}

.vital-item .vital-date {
  color: #777;
  font-size: 14px;
}

.allergy-item-wrapper {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.allergy-item-wrapper .allergy-value {
  color: #22c0e8;
  font-weight: bold;
}

.allergy-item-wrapper .allergy-name {
  color: #0bc060;
}

#ncds {
  margin-top: 20px;
}

.ncd-item-wrapper {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.ncd-item-wrapper .allergy-name {
  color: #0bc060;
}

.ncd-list-item {
  display: flex;
  flex-direction: column;
  border: 1px solid #eee;
  padding: 10px;
  width: 100%;
}

.ncd-doctor {
  color: #777;
  font-size: 15px;
}

.prescription-card {
  border: 1px solid #ccc;
  margin: 10px 0px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  cursor: pointer;
}
.prescription-card:hover {
  background-color: #fafafa;
}
.prescription-card .doctor {
  color: #22c0e8;
}
.prescription-card .date {
  font-size: 13px;
  color: #777;
}

.diagnosis-item {
  border: 1px solid #eee;
  padding: 10px;
  margin-bottom: 15px;
  width: 100%;
}

#diagnosis .encounter-list-wrap {
  margin-top: 5px;
  width: 100%;
}

@media only screen and (max-width: 500px) {
  .id-card {
    width: 100%;
  }
  .id-card .mid {
    flex-direction: column;
  }
  .id-card .mid .user-image {
    width: 10em;
    height: 11em;
  }
  .id-card .mid .info {
    width: 100%;
    padding: 17px 0;
  }
  .id-card .top img {
    width: 62%;
  }
  .id-card .mid .qr-code {
    width: 100%;
  }
}

.sign-in-inputs {
  display: flex;
  justify-content: space-between;
  line-height: 2.2em;
}
.sign-in-inputs .form-control {
  text-align: center;
  width: 95%;
  margin: 2px;
}
.sign-in-inputs .separator {
  display: flex;
}
/*.sign-in-inputs .separator:after{
 content: "-";
 display: block;
 position: relative;
 padding: 7px;
}*/

.form-control-optional {
  background-color: #ffffd6;
}

.input-group-text {
  line-height: 1;
  font-size: 0.8125rem;

  .MuiSvgIcon-root {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 500px) {
  .sign-in-inputs .form-control {
    text-align: center;
    width: 95%;
    height: 28px;
    padding: 0;
    margin: 1px;
  }
  .sign-in-inputs .separator::after {
    padding: 3px;
  }
}
/* Chrome, Safari, Edge, Opera */
.sign-in-inputs input::-webkit-outer-spin-button,
.sign-in-inputs input::-webkit-inner-spin-button,
.otp-input input::-webkit-outer-spin-button,
.otp-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.sign-in-inputs input[type="number"] {
  -moz-appearance: textfield;
}
.passwordResetSection {
  justify-content: center;
  margin-top: 100px;
}
.passwordResetSection .back-link {
  margin-top: 24px;
}

.passwordResetSection .form-control {
  // margin-bottom: 16px;
}
.passwordResetSection label {
  margin-right: 12px;
  font-weight: 500;
  margin-left: 3px;
  line-height: 1;
}
/* Style the tab */
.tab {
  float: left;
  /* border: 1px solid #ccc; */
  background-color: #f1f1f1;
  width: 30%;
  max-height: 300px;
}

/* Style the buttons inside the tab */
.tab button {
  display: block;
  background-color: inherit;
  color: black;
  padding: 22px 16px;
  width: 100%;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  transition: 0.3s;
  font-size: 17px;
}
.tablinks .lang-tam {
  font-size: 10px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current "tab button" class */
.tab button.active {
  background-color: #eaeaea;
}
/*Dropdown Btn*/
.dropbtn {
  background-color: #4caf50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 10px;
}

.dropdown-content a {
  color: black;
  padding: 8px 16px;
  text-decoration: none;
  display: block;
  border-radius: 10px;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #f1f1f1;
}
/* Style the tab content */
.tabcontent {
  float: left;
  padding: 0px 12px;
  /* border: 1px solid #ccc; */
  background-color: #fff;
  width: 70%;
  border-left: none;
  min-height: 300px;
}
.dash {
  padding: 62px 5px 5px 5px !important;
}
.dash .tabcontent {
  float: inherit;
  padding: 0px 12px;
  /* border: 1px solid #ccc; */
  background-color: transparent;
  width: 100%;
  border-left: none;
  min-height: inherit;
}

.dash .tab {
  float: inherit;
  /* border: 1px solid #ccc; */
  background-color: transparent;
  width: 100%;
  max-height: inherit;
}

.dash .card-body .tablink-2 {
  position: relative;
}

.dash .tab .row {
  // margin-bottom: 32px;
}
.dash .tab .tab-cont {
  display: inline-block;
  justify-content: center;
  align-items: center;
}
.dash h2 {
  margin-bottom: 25px;
  color: #268afd;
  font-weight: 600;
}
.dash h3 {
  font-weight: 200;
  font-size: 32px;
}
.dash .tab .tab-cont .icons {
  height: 4em;
  margin-bottom: 4px;
}
.dash .tab button {
  width: 143px;
  height: 143px;
  border-radius: 50%;
  font-size: 13px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #f3fdff;
  margin-bottom: 30px;
  /*color: #fff;*/
  /*background: rgb(95,173,255);
   background: -moz-linear-gradient(0deg, rgba(95,173,255,1) 0%, rgba(0,116,255,1) 100%);
   background: -webkit-linear-gradient(
0deg
, rgba(95,173,255,1) 0%, rgba(0,116,255,1) 100%);
   background: linear-gradient(
0deg
, rgba(95,173,255,1) 0%, rgb(0 114 251) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#5fadff",endColorstr="#0074ff",GradientType=1);*/
}
.dash .tab button:hover {
  /*background-color: #9dedfb;*/
  background-color: #cff8ff;
  -webkit-box-shadow: 0px 0px 28px -17px rgba(0, 196, 255, 1);
  -moz-box-shadow: 0px 0px 28px -17px rgba(0, 196, 255, 1);
  box-shadow: 0px 0px 28px -17px rgba(0, 196, 255, 1);
  -webkit-box-shadow: 0px 0px 19px 1px rgba(0, 187, 255, 1);
  -moz-box-shadow: 0px 0px 19px 1px rgba(0, 187, 255, 1);
  box-shadow: 0px 0px 19px 1px rgba(0, 187, 255, 1);
}
.dash .tab button.active {
  -webkit-box-shadow: 0px 0px 28px -17px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 28px -17px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 28px -17px rgba(0, 0, 0, 0.75);

  background-color: #a6f2ff;
  -webkit-box-shadow: 0px 0px 28px -17px rgba(0, 196, 255, 1);
  -moz-box-shadow: 0px 0px 28px -17px rgba(0, 196, 255, 1);
  box-shadow: 0px 0px 28px -17px rgba(0, 196, 255, 1);

  /*-webkit-box-shadow: 0px 0px 19px -3px rgba(0,13,255,1);
-moz-box-shadow: 0px 0px 19px -3px rgba(0,13,255,1);
box-shadow: 0px 0px 19px -3px rgba(0,13,255,1);

-webkit-box-shadow: 0px 0px 19px 1px rgba(0,187,255,1);
-moz-box-shadow: 0px 0px 19px 1px rgba(0,187,255,1);
box-shadow: 0px 0px 19px 1px rgba(0,187,255,1);*/
}
@media only screen and (max-width: 1366px) {
  .dash {
    padding: 20px 5px 5px 5px !important;
  }
  .dash .tab button {
    width: 100px;
    height: 100px;
  }
  .dash .tab .tab-cont .icons {
    height: 2em;
  }
}
@media only screen and (max-width: 800px) {
  .dash .tab .row {
    margin-bottom: 0;
  }
  .dash .tab button {
    width: 100%;
    height: inherit;
    border-radius: 0;
    -webkit-border-top-left-radius: 12px;
    -webkit-border-bottom-left-radius: 12px;
    -moz-border-radius-topleft: 12px;
    -moz-border-radius-bottomleft: 12px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    padding: 12px;
  }
  .dash .tab .tab-cont .icons {
    height: 2em;
    margin: 6px;
  }
}
@media only screen and (max-width: 500px) {
  .dash {
    padding: 8px 5px 5px 5px !important;
  }
  .dash .tab {
    /*width: 100%;
   flex-wrap: inherit;
   display: flex;
   flex-direction: row;
   overflow-x: auto;*/
    width: 100%;
    display: block;
    border-bottom: 1px solid #eaeaea;
    margin-bottom: 15px;
  }
  .dash .tab .row {
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .dash .tab .tab-cont {
    padding: 4px;
    width: 30%;
  }
  .dash .tab .tab-cont .icons {
    height: 1em;
    margin: 3px;
  }
  .dash .tab button {
    /*     padding: 5px;
   text-align: center;
   font-size: 11px;
   width: 107px;
   border-radius: 0;*/

    padding: 5px;
    text-align: left;
    font-size: 11px;
    width: 107px;
    border-radius: 12px;
    min-height: 42px;
    margin-bottom: 0;
  }
  .dash .tab button.active {
    /*-webkit-box-shadow: inherit;
   -moz-box-shadow: inherit;
   box-shadow: inherit;
   background-color: inherit;
   -webkit-box-shadow: inherit;
   -moz-box-shadow: inherit;
   box-shadow: inherit;
   border-bottom: 4px solid #a6f2ff;*/
    background-color: #a6f2ff;
    -webkit-box-shadow: 0px 0px 28px -17px rgba(0, 196, 255, 1);
    -moz-box-shadow: 0px 0px 28px -17px rgba(0, 196, 255, 1);
    box-shadow: 0px 0px 28px -17px rgba(0, 196, 255, 1);
  }
}
.dash .info-wrapper {
  border-left: 1px solid #d6d6d6;
  overflow-x: hidden;
  overflow-y: auto;
  height: 75vh;
  padding: 0 62px;
}
@media only screen and (max-width: 1280px) {
  .dash .info-wrapper {
    padding: 0 15px;
  }
}

@media only screen and (max-width: 1024px) {
  .dash .info-wrapper {
    height: auto;
    padding: 0 15px;
  }
}
@media only screen and (max-width: 800px) {
  .dash .info-wrapper {
    padding: 0;
    border-left: none;
  }

  .dash .info-wrapper .scroll-me {
    overflow-x: auto;
    height: 600px;
  }
}
.scroll-me {
  overflow-x: auto;
  height: 100vh;
}
.dash .info-wrapper .scroll-me {
  overflow-x: auto;
  height: 713px;
}

.dash .info-wrapper .profile {
  background: #f4fdff;
  padding: 24px;
  border-radius: 12px;
}
@media only screen and (max-width: 500px) {
  .dash .info-wrapper {
    border-left: none;
    height: inherit;
    overflow: hidden;
  }
  .dash .info-wrapper .scroll-me {
    overflow-x: inherit;
    height: inherit;
  }
  .dash .info-wrapper .profile {
    padding: 0;
  }
}
.dash .info-wrapper .upload {
  margin-bottom: 3px;
  background: #f1f1f1;
  border-radius: 12px;
  padding: 10px;
}
.dash .info-wrapper .upload img {
  width: 100px !important;
  height: auto !important;
}
.dash .info-wrapper .form-hospital {
  width: 50%;
}
@media only screen and (max-width: 768px) {
  .dash .info-wrapper .form-hospital {
    width: 100%;
  }
}
.dash .card-body {
  padding: 0;
}
.dash .card-body .tabs .tablink,
.dash .card-body .tabs .vitalSignTablink {
  padding: 8px 24px;
  margin-bottom: 12px;
  background: transparent;
  border: none;
  background: #e1faff;
  border-radius: 25px;
  margin-right: 10px;
}
.dash .card-body .tabs .tablink.active,
.dash .card-body .tabs .vitalSignTablink.active {
  background: #b5f4ff;
}

.tabs__move-btns {
  .MuiAppBar-colorDefault {
    background-color: transparent;
    box-shadow: none;

    .MuiTabScrollButton-root {
      height: 40px;
    }
  }
}


@media only screen and (max-width: 500px) {
  .dash .card-body .tabs .tablink,
  .dash .card-body .tabs .vitalSignTablink {
    padding: 3px 18px;
  }

  .dash .card-body .tabs {
    .tabs__buttons-move {
      top: -10px;
    }
  }

  .tabs__move-btns {
    .MuiAppBar-colorDefault {
      .MuiTabScrollButton-root {
        height: 25px;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .dash .card-body .tabs .tablink,
  .dash .card-body .tabs .vitalSignTablink {
    font-size: 11px;
  }
}
/*Custom CSS End*/
/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Nav Menu Essentials */
.nav-menu,
.nav-menu * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-menu ul {
  position: absolute;
  display: none;
  top: 100%;
  left: 0;
  z-index: 99;
}

.nav-menu li {
  position: relative;
  white-space: nowrap;
}

.nav-menu > li {
  float: left;
}

.nav-menu li:hover > ul,
.nav-menu li.sfHover > ul {
  display: block;
}

.nav-menu ul ul {
  top: 0;
  left: 100%;
}

.nav-menu ul li {
  min-width: 180px;
}

/* Nav Menu Arrows */
.sf-arrows .sf-with-ul {
  padding-right: 30px;
}

.sf-arrows .sf-with-ul:after {
  content: "\f107";
  position: absolute;
  right: 15px;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
}

.sf-arrows ul .sf-with-ul:after {
  content: "\f105";
}

/* Nav Meu Container */
#nav-menu-container {
  margin: 0;
}

@media (max-width: 768px) {
  #nav-menu-container {
    display: none;
  }
}

/* Nav Meu Styling */
.nav-menu a {
  padding: 22px 15px 18px 15px;
  text-decoration: none;
  display: inline-block;
  color: #fff;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-size: 15px;
  outline: none;
}

.nav-menu a:hover,
.nav-menu li:hover > a,
.nav-menu .menu-active > a {
  color: rgba(255, 255, 255, 0.75);
}

.nav-menu ul {
  margin: 4px 0 0 15px;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.08);
}

.nav-menu ul li {
  background: #fff;
  border-top: 1px solid #f4f4f4;
}

.nav-menu ul li:first-child {
  border-top: 0;
}

.nav-menu ul li:hover {
  background: #199eb8;
  transition: 0.3s;
}

.nav-menu ul li a {
  color: #333;
  transition: none;
  padding: 10px 15px;
}

.nav-menu ul li a:hover {
  color: #fff;
}

.nav-menu ul ul {
  margin: 0;
}

/* Mobile Nav Toggle */
#mobile-nav-toggle {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  margin: 8px 10px 0 0;
  border: 0;
  background: none;
  font-size: 24px;
  display: none;
  transition: all 0.4s;
  outline: none;
  cursor: pointer;
}

#mobile-nav-toggle i {
  color: #fff;
}

@media (max-width: 768px) {
  #mobile-nav-toggle {
    display: inline;
  }
}

/* Mobile Nav Styling */
#mobile-nav {
  position: fixed;
  top: 0;
  padding-top: 18px;
  bottom: 0;
  z-index: 998;
  background: rgba(0, 0, 0, 0.9);
  left: -260px;
  width: 260px;
  overflow-y: auto;
  transition: 0.4s;
}

#mobile-nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

#mobile-nav ul li {
  position: relative;
}

#mobile-nav ul li a {
  color: #fff;
  font-size: 16px;
  overflow: hidden;
  padding: 10px 22px 10px 15px;
  position: relative;
  text-decoration: none;
  width: 100%;
  display: block;
  outline: none;
}

#mobile-nav ul li a:hover {
  color: #fff;
}

#mobile-nav ul li li {
  padding-left: 30px;
}

#mobile-nav ul .menu-has-children i {
  position: absolute;
  right: 0;
  z-index: 99;
  padding: 15px;
  cursor: pointer;
  color: #fff;
}

#mobile-nav ul .menu-has-children i.fa-chevron-up {
  color: #199eb8;
}

#mobile-nav ul .menu-item-active {
  color: #199eb8;
}

#mobile-body-overly {
  width: 100%;
  height: 100%;
  z-index: 997;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  display: none;
}

/* Mobile Nav body classes */
body.mobile-nav-active {
  overflow: hidden;
}

body.mobile-nav-active #mobile-nav {
  left: 0;
}

body.mobile-nav-active #mobile-nav-toggle {
  color: #fff;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.hero {
  display: table;
  position: relative;
  /*background-image: url(../img/cover.jpg);*/
  /*background-size: cover;*/
  /*background: #f5f5f5;*/
  background: #fdfdfd;
  padding: 32px 16px;
  color: #fff;
  width: 100%;
  height: 100vh;
  box-shadow: 0px 0px 28px -17px rgba(0, 0, 0, 0.75);
}

.hero:after {
  content: "";
  z-index: 0;
  position: absolute;
  /*background: rgba(255, 255, 255, 0.82);*/
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.hero .container {
  position: relative;
  z-index: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 90vh;
}
.hero-banner-img img {
  object-fit: cover;
  width: 100%;
}
/*.hero-banner-img{
 object-fit: cover;
     width: 100%;
     background-image:url(../img/img-doc-1.png);
}*/
.hero .container .header-home {
  display: flex;
  width: 100%;
  justify-content: space-between;
  top: 0;
  z-index: 1;
}
.hero-brand {
  /* margin-bottom: 75px; */
  display: inline-block;
  opacity: 0.8;
}
.hero-brand a {
  text-shadow: -1px 1px 0 #000, 1px 1px 0 #000, 1px -1px 0 #000,
    -1px -1px 0 #000;
}
@media only screen and (max-width: 1440px) {
  .hero-brand img {
    width: 100px;
  }
}
.btn-wrapper {
  display: flex;
  justify-content: center;
  gap: 1em;
  position: relative;
}
.hero-brand:hover {
  opacity: 1;
}

.tagline {
  font-family: "Roboto", sans-serif;
  font-size: 26px;
  margin: 45px 0 75px 0;
  color: #fff;
}

/*--------------------------------------------------------------
# Stats
--------------------------------------------------------------*/
.stats-row {
  /*margin-top: 65px;*/
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* position: absolute; */
  width: 100%;
  z-index: 0;
}
.stats-row p {
  font-size: 2em;
  margin: 4px;
  line-height: 1.1em;
}
@media only screen and (max-width: 768px) {
  .stats-row p {
    font-size: 1.5em;
  }
}
.welcome-notice {
  display: flex;
  flex-direction: column;
}
.welcome-notice span {
  color: #333;
  font-size: 27px;
  /*text-shadow: 2px 2px 6px rgba(0,0,0,0.72);*/
  font-size: 30px;
  font-weight: 600;
}
/* .welcome-notice a:hover{
 color: #000000;
} */
.intro-note {
  margin: 38px 0 14px;
}

@media only screen and (max-width: 1440px) {
  .welcome-notice span {
    font-size: 18px;
  }
  .intro-note img {
    width: 50%;
  }
}
@media only screen and (max-width: 500px) {
  .intro-note {
    margin: 7px 0 14px;
  }
  .intro-note img {
    width: 100%;
  }
  .hero-brand {
    margin-bottom: 13px !important;
  }
  .welcome-notice span {
    font-size: 18px;
  }
}

.stats-col .circle {
  /* display: flex;
 justify-content: center;
 align-items: center;
 width: 130px;
 height: 130px;
 font-family: 'Roboto', sans-serif;
 font-weight: 500;
 color: #199EB8;
 background-color: #fff;
 border: 6px solid #00d0f9;
 border-radius: 50%;
 position: relative;
 font-size: 1.5em;
 font-weight: 700;
 transition: 0.8s;
 -webkit-box-shadow: 0px 0px 48px -23px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 48px -23px rgba(0,0,0,0.75); */
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 130px; */
  /* height: 69px; */
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  padding: 6px 15px;
  margin: 0 4px;
  position: relative;
  font-size: 1.2em;
  font-weight: 700;
  transition: 0.8s;
  background-color: #a6f2ff;
  color: #2980b9;
  border-radius: 25px !important;
  // -webkit-box-shadow: 0px 0px 48px -23px rgb(0 0 0 / 75%);
  -moz-box-shadow: 0px 0px 48px -23px rgba(0, 0, 0, 0.75);
}
.stats-col .circle:hover {
  background-color: #36d4ef;
  color: #2980b9;

  -webkit-box-shadow: 0px 0px 22px -2px rgba(0, 0, 0, 0.349);
  -moz-box-shadow: 0px 0px 22px -2px rgba(0, 0, 0, 0.349);
  box-shadow: 0px 0px 22px -2px rgba(0, 0, 0, 0.349);
}

@media only screen and (max-width: 500px) {
  .stats-col .circle {
    height: 26px;
    font-size: 1em;
  }
}
.stats-col .circle .stats-no {
  color: #fff;
  width: 70px;
  height: 70px;
  line-height: 70px;
  top: -25px;
  right: -15px;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  background-color: #e04f00;
  position: absolute;
  border-radius: 50%;
  font-weight: 700;
}
.stats-col .circle.active {
  background-color: #36d4ef;
  color: #2980b9;

  -webkit-box-shadow: 0px 0px 22px -2px rgba(0, 0, 0, 0.349);
  -moz-box-shadow: 0px 0px 22px -2px rgba(0, 0, 0, 0.349);
  box-shadow: 0px 0px 22px -2px rgba(0, 0, 0, 0.349);
}
/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 6px 12px;
  background-color: #fff;
  min-height: 40px;
  -webkit-box-shadow: 0px 0px 28px -17px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 28px -17px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 28px -17px rgba(0, 0, 0, 0.75);
}
.header-right {
  display: flex;
  align-items: center;
}

.header-right span {
  font-size: 17px;
}
.header-right h3 {
  font-size: 24px;
  font-weight: 600;
  /*line-height: 0;*/
  margin: 0;
}
.breadcrumbs h2 {
  font-size: 24px;
  font-weight: 300;
  margin: 0;
}

@media (max-width: 992px) {
  .breadcrumbs h2 {
    margin: 0 0 10px 0;
  }
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol li + li {
  padding-left: 10px;
}

.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #6c757d;
  content: "/";
}

@media (max-width: 768px) {
  /*.breadcrumbs .d-flex {
   display: block !important;
 }*/
  .breadcrumbs ol {
    display: block;
  }
  .breadcrumbs ol li {
    display: inline-block;
  }
}
parallax-bg.jpg

/* ==================================================
 Welcome Styling
 ================================================== */
.welcome {
  color: #fff;
  height: 490px;
  overflow: hidden;
  padding-top: 85px;
  background: url("../../images/study.png") center top no-repeat;
}

.welcome h2 {
  text-transform: uppercase;
  margin-bottom: 15px;
}

.welcome p {
  color: #fff;
  margin-bottom: 45px;
}

/* ==================================================
 Features Styling
 ================================================== */
.features {
  padding-bottom: 45px;
}

.features h2 {
  color: #199eb8;
}

.feature-col {
  display: table;
  padding-bottom: 45px;
}

.feature-col > div > div {
  display: table-cell;
  vertical-align: middle;
}

.feature-col > div > div:last-child {
  padding-left: 20px;
}

.feature-col .feature-icon {
  width: 70px;
  height: 70px;
  margin-bottom: 15px;
  background: #199eb8;
  color: #fff;
  font-size: 24px;
  text-align: center;
  line-height: 70px;
  border-radius: 50%;
  display: inline-block;
}

.feature-col h3 {
  color: #199eb8;
}

.feature-col p {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #999;
}

/* ==================================================
 Call-to-action Styling
 ================================================== */
.cta {
  background-color: #199eb8;
  padding: 25px 0;
}

.cta h2 {
  margin-bottom: 5px;
}

.cta h2,
.cta p {
  color: #fff;
}

.cta p {
  margin-bottom: 0;
  opacity: 0.75;
}

.cta .btn-ghost {
  position: relative;
  top: 13px;
}

/* ==================================================
 Portfolio Styling
 ================================================== */
.portfolio {
  background-color: #edf6ff;
  padding-bottom: 0;
}

.portfolio h2 {
  color: #199eb8;
  margin-bottom: 25px;
}

.portfolio-grid {
  margin-top: 65px;
}

.portfolio-grid .row {
  margin: 0;
}

.portfolio-grid .row > div {
  padding: 0;
}

.portfolio-grid .row > div .card img {
  width: 100%;
}

.portfolio-grid .row > div .card .portfolio-over {
  position: absolute;
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}

.portfolio-grid .row > div .card .portfolio-over > div {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.portfolio-grid .row > div .card .portfolio-over .card-title {
  color: #fff;
  font-size: 30px;
}

.portfolio-grid .row > div .card .portfolio-over .card-text {
  color: #fff;
  opacity: 0.75;
  padding: 0 45px;
}

.portfolio-grid .row > div .card:hover .portfolio-over {
  opacity: 1;
  visibility: visible;
}

/* ==================================================
 Team Styling
 ================================================== */
.team h2 {
  color: #199eb8;
}

.team .col-sm-3 {
  padding: 0;
}

.team .card > a {
  display: block;
}

.team .card img {
  width: 100%;
}

.team .card h4 {
  color: #fff;
  text-transform: uppercase;
}

.team .card p {
  font-size: 11px;
  color: #fff;
  opacity: 0.75;
  margin: 0;
  padding: 0 35px;
}

.team .card .social-nav {
  margin-bottom: 45px;
}

.team .card .social-nav a {
  color: #fff;
  font-size: 16px;
  margin: 0 4px;
}

.team .card .social-nav a:hover {
  opacity: 0.75;
}

.team .card:hover .team-over {
  opacity: 1;
  visibility: visible;
}

.team .card:hover .card-title-wrap {
  background-color: #199eb8;
}

.team .card:hover .card-title-wrap .card-title,
.team .card:hover .card-title-wrap .card-text {
  color: #fff;
}

.team .team-over {
  padding-top: 45px;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}

.team .card-title-wrap {
  padding: 15px 25px;
  position: relative;
  z-index: 9;
  background-color: #fff;
}

.team .card-title-wrap .card-title,
.team .card-title-wrap .card-text {
  font-family: "Roboto", sans-serif;
  display: block;
  margin: 0;
}

.team .card-title-wrap .card-title {
  font-size: 24px;
  color: #333;
}

.team .card-title-wrap .card-text {
  font-size: 18px;
  color: #999;
}

/* ==================================================
 Contact Section
 ================================================== */
#contact {
  background: #f7f7f7;
  padding: 80px 0;
}

#contact h2 {
  font-family: "Roboto", sans-serif;
  color: #199eb8;
}

#contact .info i {
  font-size: 32px;
  color: #199eb8;
  float: left;
}

#contact .info p {
  padding: 0 0 10px 50px;
  line-height: 24px;
}

#contact .php-email-form .validate {
  display: none;
  color: red;
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}

#contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

#contact .php-email-form .error-message br + br {
  margin-top: 25px;
}

#contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

#contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

#contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

#contact .php-email-form input,
#contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
}

#contact .php-email-form input:focus,
#contact .php-email-form textarea:focus {
  background-color: #199eb8;
}

#contact .php-email-form button[type="submit"] {
  background: #199eb8;
  border: 0;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
}

#contact .php-email-form button[type="submit"]:hover {
  background: #23c2e1;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
  width: 100%;
  background-color: #fff;
  padding: 4px 0;
}
.copyright-text {
  margin: 0;
}

.site-footer {
  background-color: #111;
  padding: 0;
}

.site-footer h2,
.site-footer p {
  color: #fff;
}

.site-footer p {
  opacity: 0.75;
  line-height: 2.0925;
}

.site-footer h2,
.site-footer .btn {
  margin-bottom: 25px;
}

.site-footer .social-nav a {
  color: #fff;
  opacity: 0.25;
}

.site-footer .social-nav a:hover {
  opacity: 1;
}

.site-footer .bottom {
  background-color: #000;
  padding: 20px 0;
}

.site-footer .bottom .list-inline,
.site-footer .bottom p {
  margin: 0;
}

.site-footer .bottom .list-inline {
  position: relative;
  top: 5px;
}

.site-footer .bottom .list-inline a {
  color: #fff;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  margin-right: 15px;
}

.site-footer .bottom .list-inline a:hover {
  color: #199eb8;
}

.site-footer .credits {
  color: #ddd;
}

.site-footer .credits a {
  color: #199eb8;
}

/* ==================================================
 Responsive Styling
 ================================================== */
@media (max-width: 61.9em) {
  section,
  .block,
  .cta {
    padding: 35px 0;
  }
  .features {
    padding-bottom: 5px;
  }
  p,
  .block p {
    margin: 0;
  }
  .hero-brand {
    margin-bottom: 35px;
  }

  .tagline {
    margin: 35px 0;
  }
  h1 {
    font-size: 32px;
    margin: 0;
  }
  h2,
  .tagline {
    font-size: 24px;
  }
  h2 {
    margin-bottom: 25px;
  }
  h3 {
    font-size: 14px;
  }
  .hero {
    padding: 75px 0;
  }
  .stats-col {
    margin-bottom: 25px;
  }
  .block {
    height: auto;
  }
  .feature-col {
    padding-bottom: 30px;
  }
  .portfolio-grid .card h3.card-title {
    font-size: 18px !important;
  }
  .portfolio-grid .card .card-text {
    font-size: 13px;
  }
  .team .team-over {
    padding-top: 20px;
  }
  .team .card .social-nav {
    margin-bottom: 15px;
    padding: 0;
  }
  .site-footer .social-nav {
    margin-bottom: 35px;
  }
  .site-footer .list-inline {
    text-align: center;
    padding-bottom: 15px;
  }
  .site-footer .list-inline li {
    display: inline-block;
  }
}
.show-mobile {
  display: none;
}

@media only screen and (max-width: 1440px) {
  .card-title {
    font-size: 23px;
  }
  .number {
    font-size: 20px;
  }
  .stats-col .circle {
    font-size: 0.8em;
  }
  .breadcrumbs {
    padding: 0px 12px;
  }
}
@media only screen and (max-width: 800px) {
  .hero {
    height: 50vh;
  }
  .hero .container {
    height: 35vh;
  }
  .show-mobile {
    display: block;
  }
}

@media (max-width: 767px) {
  .block {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media only screen and (max-width: 500px) {
  .hero {
    height: 60vh;
    padding: 22px;
  }
  .hero .lang-bar {
    position: relative;
    margin: 0 auto;
  }
  .card-body p,
  .card-body label {
    font-size: 12px;
  }
  .main-sidebar .card {
    min-height: 162px;
  }
  .breadcrumbs {
    padding: 0 !important;
  }
  .breadcrumbs .d-flex {
    display: flex;
    flex-direction: column;
  }
  .breadcrumbs h3 {
    font-size: 14px;
    width: 100%;
    background: #f3f3f3;
  }
  .user-header-info h3 {
    background: transparent;
  }
  .header-right {
    width: 100%;
    justify-content: space-between;
    padding: 4px 12px;
  }
  .header-right h3 {
    font-size: 14px;
    padding: 0;
    margin: 3px 0;
  }
  .header-right span {
    font-size: 11px;
    padding: 0 !important;
  }
  .header-right .user {
    margin: inherit;
  }
  .main {
    z-index: 0;
  }
  .main-sidebar.scroll-me {
    overflow-x: inherit;
    z-index: 1;
  }
  .stats-row {
    margin-bottom: 23px;
  }
}
.card {
  border: 1px solid #d0d3d4;
  border-radius: 5px;
}
.reg-form .form-group label {
  font-size: 13px;
  margin-bottom: 0px;
}

.reg-form button.w3-bar-item.w3-button.tablink {
  background: transparent;
  border: none;
}
.reg-form .address .form-group {
  margin-bottom: 12px;
}

/*#hidVisibleTxt:nth-letter(3) {
   color: red;
}*/

#signInInputsTbl tbody tr td {
  padding: 1px;
}
#signInInputsTbl tbody tr td .form-control {
  padding: 0px;
  text-align: center;
}

#OtpInInputsTbl {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}
#OtpInInputsTbl tbody tr td {
  padding: 3px;
}
#OtpInInputsTbl tbody tr td .form-control {
  padding: 0px;
  text-align: center;
}

#signInSaveBtns {
  /*margin-top: 16px;*/
  margin-top: 6px;
}
.pass-reset-box {
  display: none;
  margin-top: 13px;
}

/*Remove input number arrows */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

ul.timeline {
  list-style-type: none;
  position: relative;
}
ul.timeline:before {
  content: " ";
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}
ul.timeline > li {
  margin: 20px 0;
  padding-left: 20px;
  border-bottom: 1px solid #ececec;
}
ul.timeline > li:before {
  content: " ";
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #22c0e8;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
}
ul.timeline p.full-name {
  margin: 0;
  font-size: 18px;
}
ul.timeline p.date {
  margin: 0;
  margin-bottom: 4px;
}
ul.timeline p.hospital {
  margin: 0;
  font-weight: 700;
  margin-bottom: 0;
  color: #268afd;
}

ul.timeline a.joine {
  /*margin: 0;
font-weight: 700;
margin-bottom: 0;
padding: 0 12px;  
margin-top: -13px;*/
  background-color: #e4e4e4;
  border: none;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-weight: 800;
  color: #9c9c9c;
  font-size: 14px;
  padding: 8px 15px;
  /*margin-top: -15px;*/
  border-radius: 25px !important;
  margin-right: 4px;
}
ul.timeline a.joine:hover {
  color: #888888 !important;
}

ul.timeline a.joine.confirm {
  background-color: transparent;
  color: #00bd70;
  pointer-events: none;
}

ul.timeline a.joine.active {
  /*margin: 0;
 font-weight: 700;
 margin-bottom: 0;
 padding: 0 12px;  
 margin-top: -13px;*/
  background-color: #a6f2ff;
  border: none;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-weight: 800;
  color: #196296;
  font-size: 14px;
  padding: 8px 28px;
  /*margin-top: -15px;*/
  border-radius: 25px !important;
  margin-right: 4px;
}
ul.timeline a.joine.active:hover {
  background-color: #36d4ef !important;
  color: #196296 !important;
}

ul.timeline a.cancel {
  /*background-color: #fff;
 border: none;
 font-family: "Roboto", Helvetica, Arial, sans-serif;
 font-weight: 800;
 color: #196296;
 border: 1px solid #196296;
 font-size: 14px;
 padding: 5px 28px;
 margin-top: -15px;
 border-radius: 25px !important;
     margin-right: 4px;
*/

  background-color: #ff2323;
  border: none;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-weight: 800;
  color: #196296;
  border: 1px solid #ff2222;
  display: block;
  font-size: 14px;
  border-radius: 25px !important;
  height: 20px !important;
  width: 20px !important;
}
ul.timeline a.cancel img {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}
ul.timeline a.cancel:hover {
  background-color: #e60000;
}

ul.timeline li.appointment-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* certificate card style */
.certificate-card {
  border: 3px solid #000000 !important;
  -webkit-border-radius: 5px !important;
  border-radius: 5px !important;
  background-image: url("../../images/passport-back.png");
  background-repeat: no-repeat;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
  width: 400px;

  .card-header {
    padding: 0.75rem;

    .certificate-card__header-logo {
      width: 100%;
    }

    .card-title {
      color: red;
      margin-bottom: 0;
    }
  }

  .certificate-card__user-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }

  .certificate-card__qr-area-table {
    min-height: 200px;

    .certificate-card__qr-area-table-qr-code {
      width: 200px;
    }
  }

  .card-body__content {
    padding: 15px;
    position: relative;

    .card-body__content-logo {
      position: absolute;
      left: 100px;
      top: 15px;
      width: 200px;

      img {
        width: 100%;
      }
    }
  }

  .table th,
  .table td {
    vertical-align: middle;
    font-size: 13px;
  }

  .table-rotated-area {
    position: relative;
    width: 50px;
    font-size: 16px !important;
  }

  .table-rotated-area__content {
    position: absolute;
    top: 50px;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .table-rotated-area__content-text {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  .table-rotated-area__content-text ul {
    padding: 0;
    margin: 0;
    display: flex;
  }

  .table-rotated-area__content-text ul li {
    list-style: none;
    display: flex;
  }
}

.main-info-container__mobile {
  display: none;
}

// datepicker chnages
.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container input[type="text"] {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  -webkit-border-radius: 0.2rem;
  border-radius: 0.2rem;
  display: block;
  width: 100%;
  font-weight: 400;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  background-color: #fff;
}

.react-datepicker__input-container input[type="text"]:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.28);
}

.react-datepicker-popper {
  .react-datepicker {
    .react-datepicker__month-container {
      .react-datepicker__header {
        button,
        select {
          border: 1px solid #ced4da;
          background-color: #ffffff;
        }
      }

      .react-datepicker__month {
        .react-datepicker__week {
          .react-datepicker__day {
          }

          .react-datepicker__day--selected,
          .react-datepicker__day--keyboard-selected,
          .react-datepicker__month-text--keyboard-selected,
          .react-datepicker__quarter-text--keyboard-selected,
          .react-datepicker__year-text--keyboard-selected {
            background-color: #36d4ef;
          }
        }
      }
    }
  }
}

ul.timeline li.appointment-list .list-items {
  flex: 1;
  text-align: center;
}
ul.timeline li.appointment-list .list-items.btn-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-items: center;
}

.select2-container--default .select2-selection--single {
  border: 1px solid #ced4da;
  font-size: 0.8125rem;
}

.MuiSvgIcon-fontSizeSmall {
  font-size: 0.8125rem !important;
}

.bg-light-green {
  background-color: #f2fef2;
}

.vaccine-form {
  .card-title {
    color: red;
    margin-top: 30px;
    margin-bottom: 0;
  }

  .vaccine-form__header-logo {
    width: 100%;
  }

  .vaccine-form__signature-section {
    border-bottom: 2px solid #268afd;
    padding: 20px;
    margin-bottom: 20px;
  }

  .vaccine-form__signature {
    background-image: url('../../images/signature-back.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border: 2px solid #e4c8ff;
    padding: 15px;
    color: #6d18bd;
    text-align: center;

    p {
      color: #6d18bd;
      line-height: 1;
    }
  }

  .vaccine-form__signature::after {
      content: " ";
      position: absolute;
      top: -6px;
      left: 10px;
      right: 8px;
      bottom: -6px;
      border: 4px solid #e4c8ff;
      z-index: -1;
  }

  .card-body__large-text-1 {
    font-size: 30px;
    font-weight: bold;
    color: #268afd;
  }

  .card-body__large-text-2 {
    font-size: 30px;
    font-weight: bold;
    color: #777777;
  }

  .vaccine-form__primary-section {
    border-top: 2px solid #268afd;
    border-bottom: 2px solid #268afd;
  }

  .vaccine-form__large-text {
    font-size: 30px;
    font-weight: bold;
    color: #777777;
  }
}

.vaccine-form__prescription {
  border: 1px solid #999999 !important;
  padding: 5px;
  border-radius: 0 !important;
}

@media only screen and (min-width: 1600px) {
  .certificate-card {
    width: 500px;
    .card-title {
      font-size: 22px;
    }
  }
}

@media only screen and (max-width: 1440px) {
  .vaccine-form {
    .card-title {
      font-size: 18px;
      margin-top: 20px;
    }
  }

  .certificate-card {
    .card-title {
      font-size: 18px;
    }
  }
}

@media only screen and (max-width: 1280px) {
  .certificate-card {
    table {
      font-size: 13px;
    }

    .card-body__content {
      .card-body__content-logo {
        left: 100px;
        top: 0;
        width: 185px;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  ul.timeline li.appointment-list {
    display: flex;
    flex-direction: column;
    align-items: inherit;
    justify-content: inherit;
    padding: 17px;
  }
  ul.timeline li.appointment-list .list-items.item2 {
    order: 1;
  }
  ul.timeline li.appointment-list .list-items.item1 {
    order: 2;
  }
  ul.timeline li.appointment-list .list-items.item3 {
    order: 3;
  }

  ul.timeline li.appointment-list .list-items.btn-wrapper {
    justify-content: center;
  }

  .joine,
  .cancel {
    float: inherit !important;
  }

  .vaccine-form {
    .card-title {
      margin-top: 0;
    }
  }
}

@media only screen and (max-width: 768px) {
  .main-info-container__web {
    display: none;
  }

  .main-info-container__mobile {
    display: block;
  }

  .vaccine-form {
    .card-body__large-text-2 {
      font-size: 25px;
    }
  }
}

@media only screen and (max-width: 500px) {
  ul.timeline a.joine {
    margin: 0;
    font-weight: 700;
    /* margin-bottom: 0; */
    display: block;
    padding: 7px 12px;
    margin: 11px 0;
    /* padding: 4px 12px; */
    width: fit-content;
    /* margin-top: -13px; */
    float: inherit !important;
  }

  .certificate-card {
    width: 100%;

    .card-header {
      .certificate-card__header-logo {
        width: 65%;
        margin-bottom: 10px;
      }
    }

    .card-title {
      font-size: 13px;
    }

    .certificate-card__user-image {
      height: 130px;
    }

    .card-body__content {
      .card-body__content-logo {
        left: 85px;
        top: 0;
        width: 160px;
      }
    }
  }

  .vaccine-form {
    .vaccine-form__header-logo {
      width: 65%;
      margin-bottom: 10px;
    }

    .card-title {
      font-size: 13px;
    }

    .vaccine-form__signature {
      padding: 5px 10px;
    }

    .card-body__large-text-1, .card-body__large-text-2 {
      font-size: 13px;
      margin-bottom: 0;
    }
  }

  .detailed-view-wrap {
    overflow: auto;
  }
}

@media only screen and (max-width: 320px) {
  .hero {
    height: 70vh;
  }

  .certificate-card {
    .certificate-card__user-image {
      height: 100px;
    }

    .card-body__content {
      .card-body__content-logo {
        left: 75px;
        top: 0;
        width: 130px;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .timeline-view {
      display: none;
    }
  
    .detailed-view-wrap {
      left: 0;
    }
  }
